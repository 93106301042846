/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Themed } from "theme-ui";
import { Link } from "gatsby";
import Img from "gatsby-image";

const componentStyles = ({
                           colors: { muted, grey, primary, text },
                           fonts: { heading },
                           fontSizes: [, , , , fontSizeFive],
                           sizes
                         }) =>
  css`
    position: relative;
    color: ${text};
    font-family: ${heading};
    background: ${muted};
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: ${fontSizeFive};
    letter-spacing: 1px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
    transition: 0.5s ease-in-out;
    will-change: auto;
    overflow: hidden;

    > div {
      width: 100%;
      height: 100%;
    }

    &:hover,
    &:focus {
      color: ${text};
    }

    .title {
      opacity: 1.0;
      will-change: auto;
      transition: 0.6s ease-out !important;
      color: #362101;
    }

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: white;
      opacity: 0.5;
      will-change: auto;
      transition: 0.6s ease-out !important;
    }

    &:hover {
      img {
        transform: scale(1.05);
      }

      .overlay {
        opacity: 0.0;
      }

      .title {
        opacity: 0.0;
      }
    }

    .title > span {
      background: #fffbd5bb; /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, #b20a2cbb, #fffbd5bb); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, #b20a2dbb, #fffbd5bb); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      background-repeat: no-repeat;
      background-size: 100% 0.3em;
      background-position: 0 55%;
    }
  `;

const imageWrapperStyles = css`
  height: 100%;
  width: 100%;
  position: absolute;

  .gatsby-image-wrapper {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
    height: 100%;

    > div {
      padding-bottom: 0 !important;
    }

    img {
      will-change: auto;
      transition: 0.6s ease-out !important;
      transform-origin: center;
    }
  }
`;

const titleWrapperStyles = ({ fontSizes, sizes }) => css`
  font-size: ${fontSizes[5]};
  background-color: transparent;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: 10px;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 80% !important;
  height: auto !important;

  @media screen and (min-width: ${sizes.md}px) {
    font-size: ${fontSizes[6]};
  }

  @media screen and (min-width: ${sizes.lg}px) {
    font-size: ${fontSizes[7]};
  }
`;

const RecipeCard = ({ recipe: { title, slug, image }, ...props }) => {
  return (
    <Themed.a
      as={Link}
      to={`/${slug}`}
      css={theme => componentStyles(theme)}
      {...props}
    >
      <div css={imageWrapperStyles}>
        {image && image.sharp &&
        <Img fluid={image.sharp.fluid} />
        }
        {image && image.src &&
        <div className={"gatsby-image-wrapper"}>
          <div>
            <img src={image.src} height={600} width={600} />
          </div>
        </div>
        }
      </div>
      <div className="overlay" />
      <div className="title"
           css={theme => titleWrapperStyles(theme)}
      ><span>{title}</span></div>
    </Themed.a>
  );
};

export default RecipeCard;
